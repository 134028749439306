import {
  Login,
  Signup,
  VerifyUser,
  EditProfile,
  ChangePassword,
  GetInvestmentPlan,
  MakeInvestment,
  GetTransactionHistory,
  GetWithdraw,
  Withdraw,
  GetInvestment,
  contactUs,
  NewsLetter,
  ResetPassword,
  ConfirmResetPassword,
} from "../APIs";

import axios from "axios";
const state = {
  loading: false,
  ServerMessage: "",
  snackbar: false,
  getUser: null,
  Token: "",
  openOTP: false,
  getInvestmentPlan: [],
  investmentPlan: "",
  Transactions: [],
  Withdraw: [],
  GetInvestmentHistory: [],
  getLoadingTable: false,
  adminWalletAddress: null,
};
const getters = {
  getLoadingTable(state) {
    return state.getLoadingTable;
  },
  getAdminWalletAddress(state) {
    return state.adminWalletAddress;
  },
  getInvestmentHistory(state) {
    return state.GetInvestmentHistory;
  },
  getTransactionHistory(state) {
    return state.Transactions;
  },
  getInvestmentPlan(state) {
    return state.getInvestmentPlan;
  },
  investmentPlan(state) {
    return state.investmentPlan;
  },
  getToken(state) {
    return state.Token;
  },
  openOTP(state) {
    return state.openOTP;
  },
  getUser(state) {
    return state.getUser;
  },
  getLoading(state) {
    return state.loading;
  },
  ServerMessage(state) {
    return state.ServerMessage;
  },
  Snackbar(state) {
    return state.snackbar;
  },
  getWithdraw(state) {
    return state.Withdraw;
  },
};
const mutations = {
  setAdminWalletAddress(state, payload) {
    state.adminWalletAddress = payload;
  },
  setGetLoadingTable(state, payload) {
    state.getLoadingTable = payload;
  },
  setGetWithdraw(state, payload) {
    state.Withdraw = payload;
  },
  setTransactionHistory(state, payload) {
    state.Transactions = payload;
  },
  setInvestmentPlan(state, payload) {
    state.investmentPlan = payload;
  },
  setSnackbar(state, payload) {
    state.snackbar = payload;
  },
  setServerMessage(state, payload) {
    state.ServerMessage = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setUser(state, payload) {
    state.getUser = payload;
  },
  setOpenOtp(state, payload) {
    state.openOTP = payload;
  },
  setToken(state, payload) {
    if (payload) {
      state.tokenExpired = /*8.64e7*/ 20000 + Date.now();
    }
    state.Token = payload;
  },
  setSignOut(state, payload) {
    state.getUser = "";
    state.Token = "";
    payload.route.push({ name: "login" });
  },
  setGetInvestmentPlan(state, payload) {
    state.getInvestmentPlan = payload;
  },
  setGetInvestmentHistory(state, payload) {
    state.GetInvestmentHistory = payload;
  },
};
const actions = {
  Login({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(`${Login}`, {
        userName: payload.email,
        password: payload.password,
      })
      .then((res) => {
        commit("setUser", res.data.profile);
        commit("setToken", res.data.token);
        if (payload.redirect) {
          payload.route.push({ path: payload.redirect });
        } else {
          payload.route.push({ name: "Dashboard" });
        }
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  ConfirmResetPassword({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(`${ConfirmResetPassword}`, {
        email: payload.email,
        activationCode: payload.activationCode,
        newPassword: payload.newPassword,
      })
      .then((res) => {
        commit("setOpenOtp", false);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  ResetPassword({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(`${ResetPassword}`, {
        email: payload.email,
      })
      .then((res) => {
        commit("setOpenOtp", true);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  Signup({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(`${Signup}`, {
        password: payload.password,
        fullName: payload.fullName,
        userName: payload.userName,
        email: payload.email,
        referralCode: payload.referralCode,
      })
      .then((res) => {
        commit("setOpenOtp", true);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  VerifyUser({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(`${VerifyUser}`, {
        activationCode: payload.activationCode,
        email: payload.email,
      })
      .then((res) => {
        commit("setOpenOtp", false);
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        commit("setUser", res.data.profile);
        commit("setToken", res.data.token);
        setTimeout(function () {
          payload.route.push({ name: "Dashboard" });
        }, 2000);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  EditProfile({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${EditProfile}`,
        {
          ...payload,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        commit("setUser", res.data.profile);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  ChangePassword({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .put(
        `${ChangePassword}`,
        {
          oldPassword: payload.oldPassword,
          newPassword: payload.newPassword,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        commit("setToken", "");
        payload.route.push({ name: "login" });
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  GetTransactionHistory({ commit }, payload) {
    commit("setGetLoadingTable", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetTransactionHistory}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setTransactionHistory", res.data.transactions);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setGetLoadingTable", false);
      });
  },
  GetWithdraw({ commit }, payload) {
    commit("setGetLoadingTable", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetWithdraw}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setGetWithdraw", res.data.withdraw);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setGetLoadingTable", false);
      });
  },
  GetInvestment({ commit }, payload) {
    commit("setGetLoadingTable", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetInvestment}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setGetInvestmentHistory", res.data.investment);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setGetLoadingTable", false);
      });
  },
  GetInvestmentPlan({ commit }, payload) {
    commit("setGetLoadingTable", true);
    commit("setSnackbar", false);
    axios
      .get(`${GetInvestmentPlan}`, {
        headers: { Authorization: "Bearer " + payload.token },
      })
      .then((res) => {
        commit("setGetInvestmentPlan", res.data.investmentPlan);
        commit("setAdminWalletAddress", res.data.AdminWalletAddress);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setGetLoadingTable", false);
      });
  },
  MakeInvestment({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${MakeInvestment}`,
        {
          amount: payload.amount,
          investmentId: payload.investmentId,
          coin: payload.coin,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        setTimeout(function () {
          payload.route.push({ name: "SuccessFulPage" });
        }, 1000);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  NewsLetter({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${NewsLetter}`,
        {
          email: payload.email,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  ContactUs({ commit }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${contactUs}`,
        {
          email: payload.email,
          message: payload.message,
          fullName: payload.fullName,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  Withdraw({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setSnackbar", false);
    axios
      .post(
        `${Withdraw}`,
        {
          amount: payload.amount,
          walletId: payload.walletId,
          coin: payload.coin,
        },
        {
          headers: { Authorization: "Bearer " + payload.token },
        }
      )
      .then((res) => {
        commit("setSnackbar", true);
        commit("setServerMessage", res.data.message);
        dispatch("GetWithdraw", payload);
      })
      .catch((err) => {
        commit("setSnackbar", true);
        commit("setServerMessage", err.response.data.message);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
