import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  //mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      meta: { forVisitors: true },
      component: () => import("../pages/HomePage.vue"),
    },
    {
      path: "/login",
      name: "login",
      meta: { forVisitors: true },
      component: () => import("../pages/LoginPage.vue"),
    },
    {
      path: "/signup",
      name: "signup",
      meta: { forVisitors: true },
      component: () => import("../pages/SignupPage.vue"),
    },
    {
      path: "/forgotPassword",
      name: "ForgotPassword",
      meta: { forVisitors: true },
      component: () => import("../pages/ForgotPassword.vue"),
    },
    {
      path: "/AboutUs",
      name: "Aboutus",
      meta: { forVisitors: true },
      component: () => import("../pages/Aboutus.vue"),
    },
    {
      path: "/Plans",
      name: "Plans",
      component: () => import("../pages/Plans.vue"),
    },
    {
      path: "/Dashboard",
      name: "Dashboard",
      meta: { forAuth: true },
      component: () => import("../pages/Dashboard.vue"),
    },
    {
      path: "/Deposit",
      name: "Deposit",
      meta: { forAuth: true },
      component: () => import("../pages/Deposit.vue"),
    },
    {
      path: "/DepositDetails",
      name: "DepositDetails",
      meta: { forAuth: true },
      component: () => import("../pages/DepositDetails.vue"),
    },
    {
      path: "/Withdraw",
      name: "Withdraw",
      meta: { forAuth: true },
      component: () => import("../pages/Withdrawal.vue"),
    },
    {
      path: "/History",
      name: "History",
      meta: { forAuth: true },
      component: () => import("../pages/History.vue"),
    },
    {
      path: "/EditProfile",
      name: "EditProfile",
      meta: { forAuth: true },
      component: () => import("../pages/EditProfile.vue"),
    },
    {
      path: "/Security",
      name: "Security",
      meta: { forAuth: true },
      component: () => import("../pages/Security.vue"),
    },
    {
      path: "/ChangePassword",
      name: "ChangePassword",
      meta: { forAuth: true },
      component: () => import("../pages/ChangePassword.vue"),
    },
    {
      path: "/Contact",
      name: "Contact",
      component: () => import("../pages/Contact.vue"),
    },
    {
      path: "/SuccessFulPage",
      name: "SuccessFulPage",
      component: () => import("../pages/SuccessFulPage.vue"),
    },
    {
      path: "/:userName",
      name: "home",
      component: () => import("../pages/HomePage.vue"),
    },
    {
      path: "*",
      name: "page404",
      component: () => import("../pages/Page404"),
    },
  ],
});
