export const Domain = "https://lxgminers.com/api/v1";
export const DomainImage = "https://lxgminers.com/api/";
export const DomainSocket = "https://lxgminers.com";
export const Home = "https://lxgminers.com/#/";

//export const Home = "http://localhost:8080/#/";
//export const Domain = "http://localhost:2000/api/v1";
//export const DomainImage = "http://localhost:2000/api/";
//export const DomainSocket = "http://localhost:2000";

export const Login = `${Domain}/user/Login`;
export const Signup = `${Domain}/user/signup`;
export const VerifyUser = `${Domain}/user/VerifyUser`;
export const EditProfile = `${Domain}/user/EditProfile`;
export const ChangePassword = `${Domain}/user/ChangePassword`;
export const ResetPassword = `${Domain}/user/ResetPassword`;
export const ConfirmResetPassword = `${Domain}/user/ConfirmResetPassword`;

export const GetInvestmentPlan = `${Domain}/investment/GetInvestmentPlan`;
export const MakeInvestment = `${Domain}/investment/MakeInvestment`;
export const GetTransactionHistory = `${Domain}/investment/GetTransactionHistory`;
export const GetInvestment = `${Domain}/investment/GetInvestment`;

export const GetWithdraw = `${Domain}/withdraw/GetWithdraw`;
export const Withdraw = `${Domain}/withdraw/Withdraw`;

export const contactUs = `${Domain}/contact/contactUs`;
export const NewsLetter = `${Domain}/contact/NewsLetter`;
