<template>
  <v-app class="app">
    <v-app-bar v-if="getToken" app color="black" dark>
      <div @click="navigate('home')" class="d-flex align-center menuBtn">
        <v-app-bar-nav-icon
          class="menuBtn"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
        <v-img
          alt="lxgminers Logo"
          class="shrink mr-2"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/btc-removebg-preview.png?alt=media&token=9195e22f-019c-4625-9c0a-254979e568f3"
          transition="scale-transition"
          width="40"
        />

        <h2>LXGMiners</h2>
      </div>

      <v-spacer></v-spacer>
      <div class="sideNav">
        <v-btn v-if="page != 'Dashboard'" @click="navigate('Dashboard')" text>
          <v-icon color="#d4b506">mdi-home</v-icon>
          <span>Dashboard</span>
        </v-btn>

        <v-btn v-if="page != 'Deposit'" @click="navigate('Deposit')" text>
          <span>Deposit</span>
        </v-btn>

        <v-btn v-if="page != 'Withdraw'" @click="navigate('Withdraw')" text>
          <span>Withdraw</span>
        </v-btn>
        <v-btn v-if="page != 'History'" @click="navigate('History')" text>
          <span>History</span>
        </v-btn>
        <v-btn v-if="page != 'Contact'" @click="navigate('Contact')" text>
          <span>Contact</span>
        </v-btn>
        <v-menu open-on-hover offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on"> Settings </v-btn>
          </template>
          <v-list>
            <v-list-item @click="navigate('EditProfile')">
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigate('Security')">
              <v-list-item-title>Security</v-list-item-title>
            </v-list-item>
            <v-list-item @click="navigate('ChangePassword')">
              <v-list-item-title>Change Password</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn v-if="page != 'login'" @click="setSignOut()" text>
          <span>Logout</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>

      <v-btn @click="navigate('Dashboard')" large color="#d4b506">
        <v-icon large color="black">mdi-clipboard-account</v-icon>
      </v-btn>
    </v-app-bar>
    <v-app-bar v-else app color="black" dark>
      <v-app-bar-nav-icon
        class="menuBtn"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <div @click="navigate('home')" class="d-flex align-center menuBtn">
        <v-img
          alt="lxgminers Logo"
          class="shrink mr-2"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/btc-removebg-preview.png?alt=media&token=9195e22f-019c-4625-9c0a-254979e568f3"
          transition="scale-transition"
          width="40"
        />

        <h2>LXGMiners</h2>
      </div>

      <v-spacer></v-spacer>
      <div class="sideNav">
        <v-btn v-if="page != 'home'" @click="navigate('home')" text>
          <v-icon color="#d4b506">mdi-home</v-icon>
          <span>Home</span>
        </v-btn>

        <v-btn v-if="page != 'Plans'" @click="navigate('Plans')" text>
          <v-icon color="#d4b506">mdi-history</v-icon>
          <span>Plans</span>
        </v-btn>

        <v-btn v-if="page != 'Aboutus'" @click="navigate('Aboutus')" text>
          <v-icon color="#d4b506">mdi-account-group</v-icon>
          <span>About us</span>
        </v-btn>

        <v-btn v-if="page != 'Contact'" @click="navigate('Contact')" text>
          <span>Contact</span>
        </v-btn>

        <v-btn v-if="page != 'signup'" @click="navigate('signup')" text>
          <v-icon color="#d4b506">mdi-lock-open</v-icon>
          <span>Sign up</span>
        </v-btn>
        <v-btn v-if="page != 'login'" @click="navigate('login')" text>
          <v-icon color="#d4b506">mdi-lock</v-icon>
          <span>Login</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-btn @click="navigate('Dashboard')" large color="#d4b506">
        <v-icon large color="black">mdi-clipboard-account</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-progress-linear
        v-if="loadingTable"
        indeterminate
        color="yellow darken-2"
      ></v-progress-linear>
      <router-view></router-view>
      <Snackbar />
      <Loading v-if="loading" />
      <v-navigation-drawer
        v-model="drawer"
        clipped
        clipped-right
        app
        class="sideNavMenu"
      >
        <div v-if="getToken">
          <v-list light>
            <v-list-item-group v-model="selectedItem" color="#d4b506">
              <v-list-item
                @click="navigate(item.navigate)"
                v-for="(item, i) in authItems"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div v-else>
          <v-list light>
            <v-list-item-group v-model="selectedItem" color="#d4b506">
              <v-list-item
                @click="navigate(item.navigate)"
                v-for="(item, i) in items"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-navigation-drawer>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "./components/Snackbar";
import Loading from "./components/Loading";
export default {
  name: "App",

  components: {
    Loading,
    Snackbar,
  },

  data: () => ({
    drawer: false,
    selectedItem: 0,
    authItems: [
      {
        text: "Dashboard",
        icon: "mdi-clipboard-list",
        navigate: "Dashboard",
      },
      {
        text: "Deposit",
        icon: "mdi-clipboard-list",
        navigate: "Deposit",
      },
      {
        text: "Withdraw",
        icon: "mdi-clipboard-list",
        navigate: "Withdraw",
      },
      {
        text: "History",
        icon: "mdi-clipboard-list",
        navigate: "History",
      },
      {
        text: "Edit Profile",
        icon: "mdi-clipboard-list",
        navigate: "EditProfile",
      },
      {
        text: "Security Settings",
        icon: "mdi-clipboard-list",
        navigate: "Security",
      },
      {
        text: "Change Password",
        icon: "mdi-clipboard-list",
        navigate: "ChangePassword",
      },
    ],
    items: [
      {
        text: "Login",
        icon: "mdi-clipboard-list",
        navigate: "login",
      },
      {
        text: "Signup",
        icon: "mdi-clipboard-list",
        navigate: "signup",
      },
      {
        text: "About Us",
        icon: "mdi-clipboard-list",
        navigate: "Aboutus",
      },
      {
        text: "Plans",
        icon: "mdi-clipboard-list",
        navigate: "Plans",
      },
      {
        text: "Contact",
        icon: "mdi-clipboard-list",
        navigate: "Contact",
      },
    ],
  }),
  methods: {
    setSignOut() {
      let payload = {
        route: this.$router,
      };
      this.$store.commit("setSignOut", payload);
      this.$store.commit("setSnackbar", true);
      this.$store.commit("setServerMessage", "Logout");
    },
    navigate(payload) {
      this.$router.push({ name: payload });
    },
  },
  computed: {
    page() {
      return this.$route.name;
    },
    loadingTable() {
      return this.$store.getters.getLoadingTable;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
};
</script>
<style scoped>
.menuBtn {
  cursor: pointer;
}
.app {
  background-color: #181818 !important;
}
.view-enter-active,
.view-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}
.view-enter-active {
  transition-delay: 0.5s;
}
.view-enter {
  opacity: 0;
  transform: translateY(-100px);
}
.view-enter-to {
  opacity: 1;
  transform: translateY(0px);
}
.view-leave {
  opacity: 1;
  transform: translateY(0px);
}
.view-leave-to {
  opacity: 0;
  transform: translateY(100px);
}
.menuBtn {
  display: none;
}
@media only screen and (max-width: 800px) {
  .sideNav {
    display: none;
  }
  .menuBtn {
    display: inline;
  }
  .logoDiv {
    display: none;
  }
}
</style>
